import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch,} from "react-router-dom";
import {useIdleTimer} from 'react-idle-timer'
import {Config, Routes} from "../../utils/_index";
import {Footer, Header} from "../../components/misc/_index";
import * as evReducer from "../../redux/evReducer";
import {AppProvider, EvAppConfig, EVStep} from "../../misc/_index";
import {useEvSteps, useNavigation, useTranslation} from "../../misc/Hooks";
import {GetInTouchModal} from "../../components/modals/_index";
import {getImage} from "../../misc/Helpers";


export default (props) => {
    const store = useSelector(({main, ev}) => ({
        user: main.user,
        recaptchaKey: main.recaptchaKey,
        coffeeLines: main.coffeeLines,
        order: ev.order,
        paymentMethod: ev.paymentMethod,
        paid: ev.paid,
        machine: ev.machine,
        lastVisit: ev.lastVisit,
        ev,
    }))
    const t = useTranslation();
    const navigation = useNavigation();
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const steps = useEvSteps();
    const [step, setStep] = useState(0);
    const [gitModalVisible, setGitModalVisible] = useState(false);

    const {getLastActiveTime} = useIdleTimer({
        timeout: Config.EV_SESSION_TIME,
        onIdle: e => dispatch(evReducer.clearStore()),
        debounce: 500,
    })

    useEffect(() => {
        if (store.lastVisit) {
            if ((store.lastVisit + Config.EV_SESSION_TIME) <= Date.now()) {
                dispatch(evReducer.clearStore());
            }
        }

        window.addEventListener("beforeunload", function (e) {
            dispatch(evReducer.setLastVisit(getLastActiveTime()))
        });
    }, [])

    return (
        <AppProvider>
            <EvAppConfig>
                <div className="app">
                    <Header/>

                    <div className="main">
                        <div className="wrapper-bg formContainer">

                            {(store.machine && !!store.coffeeLines?.length) && (
                                <img
                                    src={getImage(store.coffeeLines.find(s => s.stack_value === store.machine.machine_coffee_type)?.stack_gallery)}
                                    alt=""
                                    className="main__capsulesImg"
                                />
                            )}

                            <div className="steps">

                                <Switch>

                                    <Route exact path={Routes.SHOP}>
                                        <Redirect to={navigation(Routes.SHOP_MACHINE_REGISTRATION)}/>
                                    </Route>


                                    <Route
                                        exact path={[
                                        `${match.path}`,
                                        ...steps.map(s => s.route)
                                    ]}
                                    >
                                        {steps.map((s, idx) => (
                                            <EVStep
                                                key={idx}
                                                index={idx}
                                                currentStep={step}
                                                steps={steps}
                                                route={s.route}
                                                title={t(s.title)}
                                                setCurrentStep={() => setStep(idx)}
                                            >
                                                {s.component}
                                            </EVStep>
                                        ))}
                                    </Route>

                                </Switch>
                            </div>

                            <div className="wrapper">
                                <div className="steps__footer par-5">
                                    <b>{t('need_help')} </b>
                                    <a className="link -primary -parentSize" onClick={() => setGitModalVisible(true)}>
                                        <b>{t('get_in_touch')}</b>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <Footer/>
                </div>


                <GetInTouchModal
                    visible={gitModalVisible}
                    onClose={() => setGitModalVisible(false)}
                />
            </EvAppConfig>
        </AppProvider>
    );
}
