import React, {useEffect, useState} from 'react';
import {Controller, FormProvider, useForm} from "react-hook-form";
import {MyInputMaskWithFile} from "./inputs/_index";
import {Button, ButtonClose} from "../buttons/_index";
import {SNHelpModal} from "../modals/_index";
import {useTranslation} from "../../misc/Hooks";


export default (props) => {
    const t = useTranslation();
    const [snHelpModalVisible, setSnHelpModalVisible] = useState(false);

    const formMethods = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: {
            serialNumber: props.data?.serial_number ?? '',
            file: '',
        },
    });
    const serialNumber = formMethods.watch('serialNumber');
    const file = formMethods.watch('file');

    const SERIAL_NUMBER_MASK = {
        pattern: /[a-zA-Z0-9]{19}/,
        inputMask: new Array(19).fill('*').join(''),
    }

    const rules = {
        serialNumber: {
            required: {
                value: true,
                message: t('validation.required'),
            },
            pattern: {
                value: SERIAL_NUMBER_MASK.pattern,
                message: t('error.serialnumber_to_short'),
            },
            validate: v => props.error ? t(`error.${props.error}`) : true,
        },
    }

    // console.log(formMethods.errors)

    // console.log(props.loading ? false : props.error ?? true)

    useEffect(() => {
        if(props.error) {
            formMethods.setError('serialNumber', {type: "validate", message: t(`error.${props.error}`)})
        }
        else {
            formMethods.clearErrors('serialNumber');
        }
    }, [props.error])


    useEffect(() => {
        if(props.data?.serial_number) {
            formMethods.setValue('serialNumber', props.data.serial_number);
        }
    }, [props.data])


    useEffect(() => {
        if(serialNumber?.toString().match(SERIAL_NUMBER_MASK.pattern)) {
            props.validateSerialNumber(serialNumber)
        }
    }, [serialNumber])


    const handleRemoveFile = () => {
        formMethods.setValue('file', '')
        props.onRemoveFile();
    }


    const onSubmit = (data) => {
        if(!props.loading)
            props.onSubmit(data);
    }


    return (
        <>
            <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>

                    <div className="grid">
                        <div>
                            <p
                                className="par-1 --semiBold"
                                dangerouslySetInnerHTML={{__html: t('ev_machine_registration.text2')}}
                            />
                        </div>
                        <div/>
                    </div>
                    <div className={`grid`}>
                        <div className="col">
                            <Controller
                                name="serialNumber"
                                control={formMethods.control}
                                rules={rules.serialNumber}
                                render={({onChange, value}) => (
                                    <MyInputMaskWithFile
                                        name="serialNumber"
                                        label={t('serial_number')}
                                        mask={SERIAL_NUMBER_MASK.inputMask}
                                        value={value}
                                        loading={props.loading}
                                        error={formMethods.errors.serialNumber}
                                        acceptFiles={"image/png,image/jpeg,image/bmp"}
                                        onChange={onChange}
                                        onUploadFile={props.onUploadFile}
                                    />
                                )}
                            />

                            <div className="serialNumber__footer">
                                <div className="serialNumber__info">
                                    {!!t('serial_number_info') && (
                                        <p className="par-3 --mb1" dangerouslySetInnerHTML={{__html: t('serial_number_info')}}/>
                                    )}

                                    <div className="par-5">
                                        <button
                                          type="button"
                                          className="link -primary -parentSize"
                                          onClick={() => setSnHelpModalVisible(true)}
                                        >{t('open_sn_modal_label')}</button>
                                    </div>
                                </div>

                                <Button
                                    type="primary"
                                    label={t('continue')}
                                    disabled={formMethods.errors.serialNumber || props.loading}
                                    onClick={formMethods.handleSubmit(onSubmit)}
                                />
                            </div>
                        </div>

                        <div className="col">
                            {!!file && (
                                <div className="thumb__box">
                                    <ButtonClose
                                        positionClass="-topRight"
                                        onClick={handleRemoveFile}
                                    />
                                    <img src={file} alt="" className="thumb__img"/>
                                </div>
                            )}
                        </div>
                    </div>


                </form>
            </FormProvider>

            <SNHelpModal
                visible={snHelpModalVisible}
                onClose={() => setSnHelpModalVisible(false)}
            />
        </>
    );
}
